import Vue from 'vue'
import Vuex from 'vuex'

import i18n from '@/i18n'

import lead from './store/modules/lead'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        language: typeof localStorage.language !== 'undefined' ? localStorage.language : 'es',
        locale: 'es-es',
        chat_active: true
    }
}

export default new Vuex.Store({
    state: getDefaultState(),
    getters: {
        getIsChatActive: (state, getters, rootState) => {
            return state.chat_active
        }
    },
    mutations: {
        changeLanguage(state, value) {
            state.language = value
            i18n.loadLanguageAsync(value)
            localStorage.setItem('language', value)
        }
    },
    actions: {},
    modules: {
        lead
    }
})
