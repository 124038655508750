import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Api } from '@/core/api.js'
import store from '@/store.js'
const api = new Api()

import init from './init.json'

const defaultLocale = localStorage.getItem('language') ? localStorage.getItem('language') : 'init'

moment.updateLocale('es', {
    calendar: {
        lastDay: '[Ayer]',
        sameDay: '[Hoy]',
        nextDay: '[Mañana]',
        lastWeek: '[El pasado] dddd',
        nextWeek: '[La próxima semana]',
        sameElse: 'dddd, DD [de] MMMM [del] YYYY'
    }
})

moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[The next week]',
        sameElse: 'dddd, DD [of] MMMM [of] YYYY'
    }
})

moment.updateLocale('pt', {
    calendar: {
        lastDay: '[Ontem]',
        sameDay: '[Hoje]',
        nextDay: '[Manhã]',
        lastWeek: 'dddd [passada]',
        nextWeek: '[A próxima semana]',
        sameElse: 'dddd, DD [de] MMMM [de] YYYY'
    }
})

moment.locale(defaultLocale)

Vue.use(VueI18n)

const languages = {
    init: init
}

const i18n = new VueI18n({
    locale: 'init',
    fallbackLocale: 'init',
    messages: Object.assign(languages)
})
const loadedLanguages = ['init']

i18n.loadLanguageAsync = function(lang) {
    // store.commit("changeLanguage",lang);

    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return api.language(lang).then(response => {
                moment.locale(lang)
                i18n.setLocaleMessage(lang, response)
                loadedLanguages.push(lang)
                return setI18nLanguage(lang)
            })
        }

        return Promise.resolve(setI18nLanguage(lang))
    } else if (lang == 'init') {
        // Download es.json lang for default if it is online
        lang = 'es'
        return api.language(lang).then(response => {
            moment.locale(lang)
            i18n.setLocaleMessage(lang, response)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        })
    }

    return Promise.resolve(lang)
}

// problems with another async function ( language, moved to router)
// i18n.loadLanguageAsync(defaultLocale);

export default i18n

function setI18nLanguage(lang) {
    i18n.locale = lang
    moment.locale(lang)
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}
