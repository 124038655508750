import { Api } from '@/core/api.js'

const api = new Api()

const state = {
    lead: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        locals: '',
        interested_version: '',
        lang: ''
    }
}

const getters = {
    getLead: (state, getters, rootState) => {
        return state.lead
    }
}

const actions = {
    addLead(context, params) {
        return api
            .post('lead/add', {
                name: params.name,
                surname: params.surname,
                email: params.email,
                phone: params.phone,
                company: params.company,
                locals: params.locals,
                lang: params.lang,
                type: params.type,
                partnerId: params.partnerId
            })
            .then(function(response) {
                context.commit('setLead', response.data)
                return response
            })
    },
    editLead(context, params) {
        return api
            .post('lead/' + params.id + '/update', {
                id: params.id,
                name: params.name,
                surname: params.surname,
                email: params.email,
                phone: params.phone,
                company: params.company,
                locals: params.locals,
                lang: params.lang
            })
            .then(function(response) {
                context.commit('setLead', response.data)
                return response
            })
    },
    validateEmail(context, params) {
        return api
            .post('lead/validate/site/email', {
                email: params.email
            })
            .then(function(response) {
                return response
            })
    },
    validateEmailUser(context, params) {
        return api
            .post('lead/validate/employee/email', {
                email: params.email
            })
            .then(function(response) {
                return response
            })
    },
    saveLead(context, params) {
        console.log(params)
        return api.post('lead/' + params.lead_id + '/init', params).then(function(response) {
            // context.commit('setLead', response.data)
            return response
        })
    }
}

const mutations = {
    setLead(state, data) {
        state.lead = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
