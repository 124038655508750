<template>
    <div class="signup-form">
        <div class="signup-content">
            <div class="form-title">
                <div class="element">
                    <div class="title-form">
                        {{ $t('public.signup.brand_title') }}
                    </div>
                </div>
            </div>
            <!-- Name -->
            <div class="form-content">
                <div class="element">
                    <div class="label">
                        <div class="value" v-if="name.length > 0">{{ $t('signup.form.name') }}</div>
                    </div>
                    <div class="input">
                        <input type="text" data-hj-allow v-model="name" class="name" :class="{ filled: name.length > 0, error: error.name }" :placeholder="$t('signup.form.name')" />
                    </div>
                    <div class="errorLabel" v-if="error.name">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                        </div>
                    </div>
                </div>
                <div class="element">
                    <div class="label">
                        <div class="value">{{ $t('signup.form.logo') }}</div>
                    </div>
                    <div class="icon-changes">
                        <input type="file" data-hj-allow id="icon_selector" style="display:none" ref="icon_product_selector" accept="image/*" @change="previewFiles($event, 'icon_product_selector')" />

                        <div @change="previewFiles($event, 'add_icon_product_selector')" class="organization-logo" @click="selectIcon()" :style="icon ? { backgroundImage: 'url(' + icon + ')' } : ''">
                            {{ icon ? '' : $t('signup.form.add_logo') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-form">
                <!-- Footer Buttons -->
                <div class="element send">
                    <div class="return" @click="returnStep()">
                        {{ $t('signup.form.return') }}
                    </div>
                    <div class="next" @click="nextStep()">
                        {{ $t('public.signup.next') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Brand',
    data() {
        return {
            name: '',
            error: {
                name: false
            },
            icon: false,
            newImage: false,
            clipper: false
        }
    },
    computed: {},
    methods: {
        nextStep() {
            if (this.validateForm()) {
                localStorage.setItem('brand', JSON.stringify({ name: this.name, image: this.icon }))
                this.$emit('nextStep')
            }
        },
        returnStep() {
            this.$emit('previousStep')
        },
        validateForm(showErrors = true) {
            let validate = true

            // Name
            if (this.name == '') {
                this.error.name = true
                validate = false
            } else {
                this.error.name = false
            }

            return validate
        },
        selectIcon() {
            this.$refs.icon_product_selector.click()
        },
        addIcon() {
            this.$refs.add_icon_product_selector.click()
        },
        deleteIcon() {
            this.icon = false
        },
        previewFiles(e, name) {
            e.srcElement.nextSibling.src = '/img/file.svg'
            this.filenames = this.$refs[name].files[0].name
            // this.$overlay.loading();
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], name)
        },
        // CREATION THUMB
        createImage(file, name) {
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = e => {
                image.onload = () => {
                    self.icon = resizeImage(image)
                }
                image.src = e.target.result
            }
            reader.readAsDataURL(file)
            this.newImage = true
        },

        validateEmail(email) {
            let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')

            return regex.test(email)
        },
        load() {
            if (localStorage.getItem('lead') != null) {
                this.name = JSON.parse(localStorage.getItem('lead')).company
            }
            if (localStorage.getItem('brand') != null) {
                this.icon = JSON.parse(localStorage.getItem('brand')).image
                this.name = JSON.parse(localStorage.getItem('brand')).name
            }
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
.signup-form {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .signup-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .form-content,
        .form-title,
        .footer-form {
            width: 100%;

            .element {
                height: auto;
                min-height: 50px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 0;

                .label {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    height: 20px;
                    width: 100%;
                    .value {
                        height: 100%;
                        width: 300px;
                        @include font-size(sm);
                        color: $inactive-s30;
                    }
                }

                .input {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include border-radius(3px);
                    height: 55px;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    input {
                        @include font-size(m);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: #000;
                        padding: 10px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.name {
                            @include background($image: img('profile_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('profile_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        &::placeholder {
                            @include font-size(m);
                            font-family: $text;
                            color: $inactive-s30;
                        }
                    }
                }

                .errorLabel {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    width: 100%;

                    .value {
                        width: 100%;

                        .bold {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text-bold;
                            margin-right: 2px;
                        }

                        .normal {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text;
                        }
                    }
                }

                &.sites {
                    select {
                        @include font-size(m);
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: $inactive-s30;
                        padding: 9px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.company {
                            @include background($image: img('location_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('location_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        option[value=''][disabled] {
                            display: none;
                        }

                        &.filled {
                            color: #000;
                        }
                    }
                }

                .options {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);

                    .option {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        border: 1px solid $neutro;
                        height: 40px;
                        width: auto;
                        margin: 5px;
                        padding: 12px;
                        cursor: pointer;

                        &.selected {
                            @include background($color: $second);
                            color: #ffffff;

                            &.error {
                                border: 1px solid $neutro-t80;
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }
                    }
                }

                &.send {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items();

                    .return {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second-t90;
                        color: $second-dark;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-t80;
                        }
                    }
                    .next {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second;
                        color: #ffffff;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-s20;
                        }
                    }
                }
                .title-form {
                    @include font-size(l);
                    text-align: center;
                    font-family: $text-bold;
                    color: $second;
                }
                .description {
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-light;
                    color: $neutro-s70;
                }
            }
            .organization-logo {
                @include display-flex();
                @include background(
                    $color: $neutro-t70,
                    //   $color: $success,
                        $image: img('image_neutro.svg'),
                    $size: 45px
                );
                @include border-radius(4px);
                @include justify-content(center);
                @include align-items(center);
                width: 100%;
                height: 200px;
                background-size: contain;
            }
        }
        .footer-form {
            @include display-flex();
            @include align-items(flex-end);
            height: 100%;

            .element {
                margin: 0px;
            }
        }

        @media screen and (min-height: 840px) {
            // background-color: red;
        }
    }
}
</style>
