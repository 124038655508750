<template>
    <div class="singup">
        <MenuTop></MenuTop>

        <div class="content">
            <div class="part left">
                <div class="label">Calcula tu ahorro</div>
                <div class="question" :class="{ filled: locals }">
                    <div class="name">¿Cuántos locales tienes?</div>
                    <!-- <div class="description">How many stores do you have?</div> -->
                    <div class="box">
                        <div class="slider">
                            <v-slider size="100%" thumb-size="100" dense hide-details :track-color="locals ? '#1A9E9A' : '#939393'" :color="locals ? '#1A9E9A' : '#939393'" v-model="locals"></v-slider>
                        </div>
                    </div>
                    <div class="box-attached">
                        <div class="number">{{ locals == 100 ? '+100' : locals }}</div>
                        <!-- <div class="units">min</div> -->
                    </div>
                </div>
                <div class="question" :class="{ filled: mode }">
                    <div class="name">¿Con qué plan de Andy quieres hacer el cálculo?</div>
                    <!-- <div class="description">Which Andy plan would you like to make your calculaitons with?</div> -->
                    <div class="box">
                        <div class="option labeling" :class="{ selected: mode == 'labeling' }" @click="changeMode('labeling')">Etiquetado</div>
                        <div class="option appcc" :class="{ selected: mode == 'appcc' }" @click="changeMode('appcc')">APPCC</div>
                        <div class="option all" :class="{ selected: mode == 'all' }" @click="changeMode('all')">Todo en uno</div>
                    </div>
                </div>
                <div class="question" :class="{ filled: logs && logs.length > 0 }">
                    <div class="name">¿Cuales son los registros que necesitas digitalizar?</div>
                    <!-- <div class="description">Which are the checklists you need to digitise?</div> -->
                    <div class="box">
                        <v-select v-model="logs" class="select-white" flat attach :background-color="logs && logs.length > 0 ? '#1A9E9A' : '#939393'" multiple rounded :items="logsItems" hide-details outlined dense filled></v-select>
                    </div>
                </div>
                <div class="question" :class="{ filled: time }">
                    <div class="name">¿Cuánto tiempo dedicas actualmente a etiquetar cada día por local?</div>
                    <!-- <div class="description">How long do you take daily labeling all your products per location?</div> -->
                    <div class="box">
                        <div class="slider">
                            <v-slider size="900" step="5" max="60" :tick-color="time ? '#1A9E9A' : '#939393'" tick-size="4" ticks="always" thumb-size="90" dense hide-details :track-color="time ? '#1A9E9A' : '#939393'" :color="time ? '#1A9E9A' : '#939393'" v-model="time"></v-slider>
                        </div>
                    </div>
                    <div class="box-attached">
                        <div class="number">{{ time == 60 ? '+60' : time }}</div>
                        <div class="units">min</div>
                    </div>
                </div>

                <div class="submit" :class="{ available: canCalculate }" @click="canCalculate ? calculate() : false">
                    Ver resultados
                </div>
            </div>
            <div class="part right" :class="{ focus: result != false }" id="result">
                <div class="label">Ahorro estimado</div>
                <div class="row">
                    <div class="name">TIEMPO de ETIQUETADO</div>
                    <div class="value">
                        <div class="number">28</div>
                        <div class="units">min/hr/local</div>
                    </div>
                    <div class="description">
                        Descongelación <br />
                        Recepción <br />
                        Cambios de envase
                    </div>
                </div>

                <div class="row">
                    <div class="name">AHORRO en PAPEL</div>
                    <div class="value">
                        <div class="number">3.000€</div>
                        <div class="units">/yr/local</div>
                    </div>
                    <div class="description">Hojas, Impresiones, archivadero, bolígrafos, tiempo de personal, tiempo de gestión</div>
                </div>

                <div class="row">
                    <div class="name">Basado en un plan de‏‏‎ ‎<b>Todo en Uno</b> 62,95€ por local al mes</div>
                </div>
                <div class="row">
                    <div class="name inline">Ahorras un total de:‏‏‎‏‏‎‎</div>
                    <div class="value">
                        <div class="number">‎‎‏‏‎‏‏‎ ‎60,000</div>
                        <div class="units">/yr</div>
                    </div>
                </div>
                <div class="try">
                    Prueba Andy
                </div>
            </div>
            <div class="andy-calculating" :class="{ unfocus: result != false }"></div>
        </div>
    </div>
</template>

<script>
import MenuTop from '@/components/layout/MenuTop'

export default {
    components: { MenuTop },
    name: 'Calculator',
    data() {
        return {
            // CALCULATOR
            locals: 0,
            mode: false,
            logs: false,
            time: 0,

            result: false
        }
    },
    computed: {
        logsItems() {
            return ['Registro de temperatura', 'Registro de limpieza', 'Registro de recepción producto']
        },

        canCalculate() {
            return this.locals && this.mode && this.logs && this.logs.length > 0 && this.time
        }
    },
    methods: {
        changeMode(mode) {
            this.mode = mode
        },

        calculate() {
            this.result = {}

            // SCROLL TO RESULT
            var element = document.getElementById('result')
            var top = element.offsetTop
            window.scrollTo({ top: document.body.clientWidth > 800 ? top - 60 : top - 40, behavior: 'smooth' })
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.singup {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #f3fafa;
    @include background($image: img('background.svg'), $position: center, $size: 1100px);

    .content {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: calc(100%);
        width: 100%;
        padding-top: 70px;
        @include background($image: img('ellipse_second_t10.svg'), $position: bottom -300px right -150px, $size: clamp(unset, 50%, 1000px));
        // background: linear-gradient(284.46deg, #8dbacc 0%, #dcf4ed 100%);
        overflow-y: hidden;
        overflow: hidden;

        padding: 120px 50px 80px;

        .andy-calculating {
            @include background($image: img('andy_ahorrando.svg'), $size: 100%);
            height: 500px;
            width: 500px;
            position: absolute;
            bottom: -100px;
            right: -50px;
            z-index: 51;

            &.unfocus {
                z-index: 1;
            }
        }

        // .eclipse {
        //     position: fixed;
        //     height: 250px;
        //     width: 250px;
        //     top: 5%;
        //     z-index: 0;

        //     &.accent {
        //     }
        // }

        .part {
            padding: 40px 40px;
            z-index: 50;

            &.left {
                @include box-shadow($h: 0px, $v: 4px, $b: 10px, $s: 0px, $c: rgba(0, 0, 0, 0.15));
                @include border-radius(70px);
                background-color: #fff;
                height: auto;
                float: left !important;
                width: calc(60% - 50px);
                max-width: 600px;
                overflow: hidden;
                margin-right: 100px;

                .label {
                    @include font-size(40px);
                    color: $neutro-s70;
                    font-family: $text-bold;
                }

                .question {
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    margin-top: 30px;
                    width: 100%;
                    color: $neutro-s40;

                    &.filled {
                        color: #1a9e9a;

                        .box-attached {
                            color: #1a9e9a;
                        }
                    }

                    .name {
                        @include font-size(m);
                        font-family: $text-bold;
                        width: calc(100% - 80px);
                        color: inherit;
                    }

                    .description {
                        color: inherit;
                        font-family: $text-light;
                        width: calc(100% - 80px);
                    }

                    .box {
                        @include display-inline-flex();
                        @include justify-content(space-between);
                        @include align-items();
                        width: calc(100% - 90px);
                        margin: 10px 0 0;
                        height: fit-content;

                        .slider {
                            width: 100%;
                            height: fit-content;

                            .v-input {
                                margin: 0px !important;
                            }
                        }

                        .option {
                            @include display-inline-flex();
                            @include justify-content();
                            @include font-size(sm);
                            @include border-radius(20px);
                            width: 30%;
                            padding: 20px 20px;
                            color: #fff;
                            cursor: pointer;
                            opacity: 1 !important;

                            &.labeling,
                            &.appcc,
                            &.all {
                                background-color: $neutro-s40;

                                &.selected {
                                    opacity: 1;
                                    filter: grayscale(0%);
                                }
                            }

                            &.labeling.selected {
                                background-color: #1a9e9a;
                            }
                            &.appcc.selected {
                                background-color: #758c3e;
                            }
                            &.all.selected {
                                background-color: #475b96;
                            }
                        }

                        .v-select {
                            border: none !important;
                            color: #fff !important;
                        }
                    }

                    .box-attached {
                        @include display-inline-flex();
                        @include justify-content(center);
                        @include align-items(baseline);
                        width: 80px;
                        height: fit-content;
                        color: $neutro-s40;

                        .number,
                        .units {
                            @include display-inline-flex();
                            @include justify-content(bottom);
                            @include align-items(bottom);
                            font-family: $text-bold;
                            color: inherit;
                        }

                        .number {
                            width: fit-content;
                            @include font-size(xl);
                        }

                        .units {
                            width: fit-content;

                            @include font-size(s);
                        }
                    }
                }

                .submit {
                    @include font-size(l);
                    @include border-radius(40px);
                    font-family: $text-bold;
                    background-color: $neutro-s40;
                    color: #fff;
                    margin: 0 auto;
                    width: fit-content;
                    margin-top: 30px;
                    padding: 5px 30px;

                    &.available {
                        background-color: #692c92;
                        cursor: pointer;
                    }
                }
            }
            &.right {
                height: auto;
                width: calc(40% - 50px) !important;
                max-width: 400px;
                opacity: 0.3;
                // background-color: #f3fafa;
                @include border-radius(70px);

                &.focus {
                    opacity: 1;
                }

                div,
                b {
                    @include display-flex();
                    color: #007672;
                    place-self: baseline;
                }

                .label {
                    @include font-size(40px);
                    color: $neutro-s70;
                    font-family: $text-bold;
                    margin-bottom: 30px;
                }

                .row {
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    margin-bottom: 40px;

                    .name {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include font-size(ml);
                        width: 100%;

                        &.inline {
                            width: unset;
                        }
                    }

                    .value {
                        display: inline;
                        .number {
                            @include font-size(xl);
                            @include display-inline-flex();
                            font-family: $text-bold;
                        }
                        .units {
                            @include display-inline-flex();
                        }
                    }

                    .description {
                        @include display-flex();
                        @include font-size(sm);
                        width: 100%;
                    }
                }

                .try {
                    @include font-size(xl);
                    @include border-radius(40px);
                    font-family: $text-bold;
                    background-color: #ffc700;
                    color: #000;
                    margin: 0 auto;
                    width: fit-content;
                    padding: 7px 50px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .singup {
        height: 100%;
        @include background($image: img('background.svg'), $position: left top, $size: 150vh);

        .content {
            padding: 90px 0 0 0;
            @include background($image: img('ellipse_second_t10.svg'), $position: bottom -150px right -50px, $size: 350px);

            .andy-calculating {
                @include background($image: img('andy_ahorrando.svg'), $size: 100%);
                height: 300px;
                width: 300px;
                position: absolute;
                bottom: -50px;
                right: -50px;
            }

            .part {
                padding: 40px 20px;
                &.left,
                &.right {
                    width: calc(100% - 40px) !important;
                    max-width: unset;
                    margin: 20px auto;
                }

                &.left {
                    .question {
                        .name {
                            width: calc(100%);
                        }

                        .description {
                            width: 100%;
                        }

                        .box {
                            width: 100%;

                            .option {
                                @include display-inline-flex();
                                @include justify-content();
                                @include align-items();
                                @include font-size(sm);
                                @include border-radius(20px);
                                width: 31%;
                                padding: 20px 10px;
                                height: 60px;
                                color: #fff;
                                cursor: pointer;
                            }
                        }

                        .box-attached {
                            margin-top: 5px;
                            margin-left: auto;
                        }
                    }
                }

                &.right {
                    .try {
                        @include justify-content();
                        @include align-items();
                        padding: 7px 0px;
                        width: 90%;
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
}
</style>

// CUSTOM VUETIFY STYLES
<style lang="scss">
.question {
    &.filled {
        .v-slider__tick {
            background-color: #1a9e9a;
        }
    }

    .select-white.v-select {
        border-radius: 12px !important;
        border: none !important;

        .v-select__selection {
            color: white !important;
            width: 100%;
            margin: 6px 0 !important;
        }

        .v-input__append-inner {
            height: 100%;
            margin: 0 !important;

            div {
                height: 100%;
            }

            i {
                content: '';
                color: transparent;
                @include background($image: img('down_arrow_ffffff.svg'), $position: center, $size: 80%);
                margin-top: 0 !important;
                height: 100%;
            }
        }

        .v-input__slot {
            padding: 5px 12px !important;
        }
    }

    .v-input__slider .v-slider .v-slider__thumb {
        display: inherit;
        cursor: pointer;
        width: 24px;
        height: 24px;
        left: -12px;
    }

    .v-slider__tick {
        height: 12px !important;
        top: calc(50% - 6px) !important;
        background-color: $neutro-s30;
        // cursor: pointer;
        width: 3px !important;
    }
}
</style>
