<template>
    <div name="modal" :class="type">
        <div id="loading" :class="{ blocking: blocking }" v-if="loading && show && type != 'print'" @click="onClick" :style="[{ backgroundColor: color }, styles]">
            <p v-if="message != '' && !timeout.action.show" class="message" v-html="message"></p>

            <div v-if="timeout.action.show" class="redirect">
                <p v-if="timeout.action.message" v-html="timeout.action.message"></p>
                <button v-if @click="timeoutAction">{{ timeout.action.actionText }}</button>
            </div>
        </div>
        <div id="overlay" :class="{ blocking: blocking }" v-else-if="show && type != 'print'" @click="onClick" :style="[{ backgroundColor: color }, styles]"></div>
        <!--
    <div id="overlay" :class="{'blocking':blocking}" v-else-if="show && type == 'print'" @click="onClick" :style="[{backgroundColor : color}, styles]">
      <span class="message" v-if="dPrint && dPrint.message">{{dPrint.message}}</span>
      <div class="label-box hide-scrollbar" v-if="dPrint && dPrint.data">
        <div v-for="dt in dPrint.data" class="line">
          <span class="label">{{ dt.label }}</span>
          <span class="value">{{ dt.value }}</span>
        </div>
      </div>

    </div>-->
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'

export default {
    data() {
        return {
            type: 'default',
            show: false,
            closable: true,
            loading: false,
            color: '2b375aa6',
            message: '',
            blocking: false,
            dPrint: {},
            styles: {},
            timeout: {
                variable: false,
                timing: 60000,
                autoredirect: false,
                action: {
                    actionText: i18n.t('assets.overlay.timeout.actionText'),
                    message: i18n.t('assets.overlay.timeout.message'),
                    show: false,
                    url: '/home',
                    callAction: function() {}
                }
            },
            callClick: null
        }
    },
    methods: {
        onClick(event) {
            if (this.callClick && typeof this.callClick === 'function') {
                this.callClick(this, store)
            } else {
                if (!this.closable) {
                    return
                }
                this.selfClose()
            }
        },
        selfClose() {
            this.show = false
            this.timeout.action.show = false
            this.clearSelfClose()
            document.body.style.overflow = 'auto'
        },

        startSelfClose() {
            var self = this
            clearTimeout(this.timeout.variable)
            self.timeout.action.show = false

            this.timeout.variable = setTimeout(function() {
                if (self.timeout.autoredirect) {
                    self.timeoutAction()
                } else {
                    //   self.timeout.action.show = true
                }
            }, self.timeout.timing)
        },
        timeoutAction() {
            let self = this
            self.timeout.action.callAction()
            router.push(self.redirectPath(self.timeout.action.url))
            self.selfClose()
        },
        clearSelfClose() {
            this.timeout.action.show = false
            clearTimeout(this.timeout.variable)
        },
        redirectPath(firstResult) {
            var visible = []
            var tools = store.getters['getTools']
            var result = firstResult

            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            if (visible.length == 1) {
                result = tools[visible[0]].href
            }
            return result
        }
    },
    mounted() {
        let self = this

        if (self.loading) {
            self.startSelfClose()
        }
    },
    beforeDestroy() {
        let self = this

        if (self.loading) {
            self.clearSelfClose()
        }
    }
}
</script>

<style lang="scss">
#overlay {
    background-color: rgba($second-s80, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-overlay-default;

    &.blocking {
        z-index: $z-overlay-blocking;
    }
}

#loading {
    @include background(
        $image: (
            img('loader_light.svg')
        ),
        $color: rgba($main-s40, 0.65),
        $size: (
            100px,
            cover
        )
    );
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-overlay-default + 1;

    &.blocking {
        z-index: $z-overlay-blocking + 10;
    }

    img {
        position: fixed;
        width: 200px;
        height: 100px;
        top: 50%;
        left: 50%;
        margin-top: -50px; /* Negative half of height. */
        margin-left: -100px; /* Negative half of width. */
    }

    .message {
        position: fixed;
        width: calc(100% - 50px);
        height: auto;
        top: calc(50% + 120px);
        left: 50px;
        margin-top: -50px; /* Negative half of height. */
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        padding-right: 50px;
        @include font-size(l);
        font-family: $conden-light;
    }
    .redirect {
        position: fixed;
        width: calc(100% - 50px);
        height: auto;
        top: calc(50% + 120px);
        left: 50px;
        margin-top: -50px; /* Negative half of height. */
        text-align: center;
        padding-right: 50px;

        > p {
            @include font-size(l);
            font-family: $conden-light;
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            display: block;
            width: 80%;
            margin: 0 auto 24px auto;
        }
        > button {
            @include background($color: #fff);
            @include border-radius(3px);
            @include font-size(ml);
            font-family: $conden-bold;
            color: $main;
            padding: 6px 9px;
        }
    }
}

.print {
    #overlay,
    #loading {
        // background-color: rgba(255,255,255,0.8);
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-direction(column);

        .message {
            @include font-size(ml);
            font-family: $text-bold;
            color: #fff;
            text-align: center;
            margin: 0 auto;
            display: block;
            width: 100%;
        }

        .label-box {
            @include border-radius($default-border-radius);
            @include background($color: #fff);
            display: block;
            margin: 0 auto;
            min-width: 300px;
            width: auto;
            max-width: 60%;
            max-height: 80vh;
            overflow: auto;

            .line {
                display: block;
                width: 100%;

                .label,
                .value {
                    display: inline-block;
                    min-width: 200px;
                    width: auto;
                    padding: 6px;
                }
                .label {
                    display: inline-block;
                    max-width: 30%;
                    text-align: right;
                    text-transform: capitalize;

                    &:first-of-type {
                        // display: none;
                    }
                }
                .value {
                    display: inline-block;
                    max-width: 70%;
                    font-family: $text-bold;

                    &:first-of-type {
                        font-family: $text;
                    }
                }
            }
        }
    }
}
</style>
