import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Hotjar from 'vue-hotjar'

// LANGUAGE
import i18n from './i18n'
Vue.use(i18n)

// MIXINS
import mixin from './core/mixin'

// LAYOUTS
import Empty from './layouts/Empty-Layout.vue'

// overlay
import overlay from './plugins/overlay'
Vue.use(overlay)

// COMPONENTS
Vue.component('empty-layout', Empty)

// VUETIFY
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify'

const vuetify = new Vuetify({
    theme: { disable: true },
    icons: {
        iconfont: 'md'
    }
})
Vue.use(Vuetify)

Vue.use(Hotjar, {
    id: process.env.VUE_APP_HotjarId,
    isProduction: true
})

Vue.config.productionTip = false

new Vue({
    el: '#app',
    i18n,
    store,
    router,
    mixins: [mixin],
    components: { App },
    template: '<App/>',
    vuetify
    /* mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '~@/core/store.js')
    document.head.appendChild(recaptchaScript)
  }, */
})
