<template>
    <div class="signup-container">
        <MenuTop></MenuTop>
        <div class="signup-form-content">
            <transition :name="transitionName">
                <div class="page" key="0" v-if="currentStep == 'intro'">
                    <Intro @nextStep="changeStep('lead', 'next')"></Intro>
                </div>
                <div class="page" key="1" v-if="currentStep == 'lead'">
                    <LeadForm @previousStep="changeStep('intro', 'previous')" @nextStep="changeStep('user', 'next')"></LeadForm>
                </div>
                <!-- <div class="page" key="2" v-if="currentStep == 'brand'">
                    <Brand @previousStep="changeStep('lead', 'previous')" @nextStep="changeStep('user', 'next')"></Brand>
                </div> -->
                <!-- <div class="page" key="3" v-if="currentStep == 'site'">
                    <Site @previousStep="changeStep('brand', 'previous')" @nextStep="changeStep('user', 'next')"></Site>
                </div> -->
                <div class="page" key="2" v-if="currentStep == 'user'">
                    <User @previousStep="changeStep('lead', 'previous')" @nextStep="changeStep('finish', 'next')"></User>
                </div>
                <div class="page" key="3" v-if="currentStep == 'finish'">
                    <Outro @previousStep="changeStep('user', 'previous')"></Outro>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Outro from '@/components/signup/outro.vue'
import Site from '@/components/signup/site.vue'
import User from '@/components/signup/user.vue'
import Brand from '@/components/signup/brand.vue'
import Intro from '@/components/signup/intro.vue'
import LeadForm from '@/components/signup/leadForm.vue'
import MenuTop from '@/components/layout/MenuTop'
export default {
    components: {
        MenuTop,
        LeadForm,
        Intro,
        Brand,
        User,
        Site,
        Outro
    },
    name: 'Signup',
    data() {
        return {
            language: '',
            languages: ['es', 'en', 'pt','de','fr'],
            currentStep: 'intro',
            transitionName: 'next'
        }
    },
    methods: {
        changeStep(step, transitionName) {
            // this.$overlay.loading()
            this.transitionName = transitionName
            this.currentStep = step
            // this.$overlay.hide()
        },
        load() {
            if (localStorage.getItem('lead') != null) {
                this.currentStep = 'user'
            }
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
// Transition slide
.next-enter,
.next-leave-to {
    transform: translateX(100%);
    opacity: 0;
}

.next-enter-active {
    transition: all 0.5s ease;
}

.next-leave-active {
    transform: translateX(-100%);
    transition: all 0.5s ease;
}
.previous-enter,
.previous-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

.previous-enter-active {
    transition: all 0.5s ease;
}

.previous-leave-active {
    transform: translateX(100%);
    transition: all 0.5s ease;
}

// end transition slide

.signup-container {
    width: 100%;
    height: 100%;
    @include background($color: #ffffff);

    .signup-form-content {
        width: 100%;
        height: 100%;

        .page {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
