<template>
    <v-app id="app">
        <component :is="layout">
            <router-view />
        </component>
    </v-app>
</template>

<script>
import { Api } from '@/core/api.js'
const api = new Api()
const default_layout = 'empty'

import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from './router'

Vue.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GoogleAnaylticsKey },
        includes: [{ id: process.env.VUE_APP_GoogleAnalyticsKeyCetrex }],
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_title: to.name,
                page_path: to.path
            }
        }
    },
    router
)

export default {
    computed: {
        layout() {
            var layout = typeof this.$route.meta.layout !== 'undefined' ? this.$route.meta.layout : default_layout
            return layout + '-layout'
        }
    },
    created() {
        // const db = new Db()
        // console.log('Call init')
        // db.init()
    }
}
</script>

<style lang="scss">
#app {
    width: 100%;
    height: 100%;
}
</style>
