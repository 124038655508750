<template>
    <div class="signup-form">
        <div class="signup-content">
            <div class="form-title">
                <div class="element">
                    <div class="title-form">
                        {{ $t('public.signup.site.title') }}
                    </div>
                </div>
                <div class="element">
                    <div class="description" v-html="$t('public.signup.site.description')"></div>
                </div>
            </div>
            <!-- Name -->
            <div class="form-content">
                <form>
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="site.name.length > 0">{{ $t('public.signup.site.name') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" data-hj-allow v-model="site.name" class="name" :class="{ filled: site.name.length > 0, error: error.name }" :placeholder="$t('public.signup.site.name')" />
                        </div>
                        <div class="errorLabel" v-if="error.name">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Identification -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="site.identification.length > 0">{{ $t('public.signup.site.identification') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" data-hj-allow v-model="site.identification" class="name text-lowercase" :class="{ filled: site.identification.length > 0, error: error.identification }" :placeholder="$t('public.signup.site.identification')" />
                        </div>
                        <div class="label-description">
                            <div class="value">{{ $t('public.signup.site.identification_description') }}</div>
                        </div>
                        <div class="errorLabel" v-if="error.identification">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="site.email.length > 0">{{ $t('public.signup.email') }}</div>
                        </div>
                        <div class="input">
                            <input type="email" data-hj-allow autocomplete="email" v-model="site.email" class="email" :class="{ filled: site.length > 0, error: error.email || error.email_taken }" :placeholder="$t('public.signup.email')" />
                        </div>
                        <div class="errorLabel" v-if="error.email">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                                <div class="normal">{{ $t('signup.form.error.email') }}</div>
                            </div>
                        </div>
                        <div class="errorLabel" v-if="error.email_taken">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                                <div class="normal">{{ $t('signup.form.error.email_taken') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Password -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="site.password.length > 0">{{ $t('public.signup.site.password') }}</div>
                        </div>
                        <div class="input">
                            <input type="password" @keypress="NumbersOnly" autocomplete="new-password" v-model="site.password" class="password" :class="{ filled: site.password.length > 0, error: error.password }" :placeholder="$t('public.signup.site.password1')" />
                        </div>
                        <div class="errorLabel" v-if="error.password">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Fecha inicio piloto -->
                    <!-- <div class="element">
                        <div class="label">
                            <div class="value">{{ $t('public.signup.site.start_date') }}</div>
                        </div>
                        <div class="input">
                            <input type="date" v-model="site.start_date" class="start-date" :class="{ filled: site.start_date.length > 0, error: error.start_date }" :placeholder="$t('public.signup.site.start_date')" />
                        </div>
                        <div class="errorLabel" v-if="error.start_date">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="element">
                        <div class="label-finish">
                            {{ $t('public.signup.site.process_end') }}
                        </div>
                    </div>
                </form>
            </div>

            <div class="footer-form">
                <!-- Footer Buttons -->
                <div class="element send">
                    <div class="return" @click="returnStep()">
                        {{ $t('signup.form.return') }}
                    </div>
                    <div class="next" @click="nextStep()">
                        {{ $t('public.signup.next') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Site',
    data() {
        return {
            site: {
                name: '',
                identification: '',
                email: '',
                password: ''
                // start_date: ''
            },
            error: {
                name: false,
                identification: false,
                email: false,
                password: false,
                email_taken: false
            },
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    computed: {
        validButton() {
            let showErrors = false
            let validForm = this.validateForm(showErrors)
            return validForm
        }
    },
    methods: {
        nextStep() {
            this.validateForm().then(response => {
                this.$overlay.hide()
                if (response) {
                    this.site.identification = this.site.identification.replace(/\s+/g, '')
                    localStorage.setItem('site', JSON.stringify(this.site))
                    this.$emit('nextStep')
                }
            })
        },
        returnStep() {
            this.$emit('previousStep')
        },
        async validateForm(showErrors = true) {
            this.$overlay.loading()
            let validate = true

            // Name
            if (this.site.name == '') {
                this.error.name = true
                validate = false
            } else {
                this.error.name = false
            }

            // identification
            if (this.site.identification == '') {
                this.error.identification = true
                validate = false
            } else {
                this.error.identification = false
            }

            // Email
            if (this.site.email == '') {
                this.error.email = true
                validate = false
            }

            if (showErrors && !this.emailReg.test(this.site.email)) {
                this.error.email = true
                validate = false
            } else {
                this.error.email = false
            }
            if (!this.error.email) {
                var resp = await this.$store.dispatch('lead/validateEmail', { email: this.site.email })
                if (!resp.status) {
                    this.error.email_taken = true
                    validate = false
                } else {
                    console.log(resp)
                    this.error.email_taken = false
                }
            }

            // password
            if (this.site.password == '' || this.site.password.length < 8) {
                this.error.password = true
                validate = false
            } else {
                this.error.password = false
            }

            return validate
        },
        load() {
            let site = localStorage.getItem('site')
            if (site) {
                this.site = JSON.parse(site)
            }
        },
        NumbersOnly(evt) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
.signup-form {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .signup-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .form-content,
        .form-title,
        .footer-form {
            width: 100%;

            .element {
                height: auto;
                min-height: 50px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 0;

                .label {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    height: 20px;
                    width: 100%;
                    .value {
                        height: 100%;
                        width: 300px;
                        @include font-size(sm);
                        color: $inactive-s30;
                    }
                }

                .input {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include border-radius(3px);
                    height: 55px;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    input {
                        @include font-size(m);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: #000;
                        padding: 10px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.name {
                            @include background($image: img('profile_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('profile_000000.svg'), $size: 20px, $position: left 10px center);
                            }

                            &.text-lowercase {
                                text-transform: lowercase;
                            }
                        }

                        &.email {
                            @include background($image: img('mail_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('mail_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.password {
                            @include background($image: img('padlock_neutro.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('padlock.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.start-date {
                            @include background($image: img('calendar_neutro.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('calendar.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        &::placeholder {
                            @include font-size(m);
                            font-family: $text;
                            color: $inactive-s30;
                        }
                    }
                }

                .errorLabel {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    width: 100%;

                    .value {
                        width: 100%;

                        .bold {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text-bold;
                            margin-right: 2px;
                        }

                        .normal {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text;
                        }
                    }
                }

                &.sites {
                    select {
                        @include font-size(m);
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: $inactive-s30;
                        padding: 9px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.start_date {
                            @include background($image: img('location_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('location_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        option[value=''][disabled] {
                            display: none;
                        }

                        &.filled {
                            color: #000;
                        }
                    }
                }

                .options {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);

                    .option {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        border: 1px solid $neutro;
                        height: 40px;
                        width: auto;
                        margin: 5px;
                        padding: 12px;
                        cursor: pointer;

                        &.selected {
                            @include background($color: $second);
                            color: #ffffff;

                            &.error {
                                border: 1px solid $neutro-t80;
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }
                    }
                }

                // &.sites.desktop {
                //     height: auto;
                // }

                // &.sites.mobile {
                //     display: none;
                // }

                &.terms_and_conditions {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    margin: 0;

                    .checkbox {
                        width: auto;
                        margin-right: 6px;

                        input {
                            width: 15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }

                    .description {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);

                        .part1 {
                            cursor: pointer;

                            &.error {
                                color: $error;
                            }
                        }

                        .part2 {
                            margin-left: 5px;

                            a {
                                text-decoration: underline;
                                text-decoration-color: $second;
                            }

                            &.error {
                                color: $error;
                            }
                        }
                    }
                }

                &.send {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items();

                    .return {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second-t90;
                        color: $second-dark;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-t80;
                        }
                    }
                    .next {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second;
                        color: #ffffff;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-s20;
                        }
                    }
                }
                .title-form {
                    @include font-size(l);
                    text-align: center;
                    font-family: $text-bold;
                    color: $second;
                }
                .description {
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-light;
                    color: $neutro-s70;
                }
            }
        }
        .footer-form {
            @include display-flex();
            @include align-items(flex-end);
            height: 100%;

            .element {
                margin: 0px;
            }
        }

        @media screen and (min-height: 840px) {
            // background-color: red;
        }
    }
}
</style>
