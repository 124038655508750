<template>
    <div class="chat-container" id="chat">
        <div class="box-selector">
            <div class="chat" :class="{ selected: chatSelected }" @click="changeTabBar('chat')">
                {{ 'Chat' }}
            </div>
            <div class="auto-help" @click="changeTabBar('auto-help')" :class="{ selected: !chatSelected }">
                {{ 'Autoayuda' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Chat',
    data() {
        return {
            chatLoading: true,
            chatSelected: true
        }
    },
    computed: {
        chatActive() {
            return this.$store.getters.getIsChatActive
        }
    },
    methods: {
        changeTabBar(tab) {
            if (tab == 'chat' && this.chatSelected) return
            if (tab == 'auto-help' && !this.chatSelected) return
            if (tab == 'chat') {
                let query = Object.assign({}, this.$route.query)
                delete query.bot ? delete query.bot : ''
                this.$router.replace({ query })
                this.$router.push({ name: 'Home' })
                window.location.reload()
            } else {
                this.$router.push({ name: 'Home', query: { bot: 1 } })
                window.location.reload()
            }
        },
        hideChat() {
            let btn = window.document.getElementsByClassName('zsiq_float')
            let hideBtn = setInterval(() => {
                if (btn && btn[0]) {
                    clearInterval(hideBtn)
                    btn[0].style.setProperty('display', 'none', 'important')
                }
            }, 100)
        },
        openChatLogic() {
            let self = this
            let openChatBtn = window.document.getElementById('zsiq_float')
            let hideChatInterval = setInterval(() => {
                if (openChatBtn) {
                    clearInterval(hideChatInterval)
                    // openChatBtn.style.setProperty('visibility', 'hidden', 'important')
                    openChatBtn.style.setProperty('opacity', '1', 'important')
                    self.openChat()
                }
            }, 100)
        },
        closeChat() {
            let iframe = window.document.getElementById('siqiframe')
            let iframeloaded = setInterval(() => {
                if (iframe) {
                    clearInterval(iframeloaded)
                    iframe.style.setProperty('display', 'none', 'important')
                    let childs = iframe.contentWindow.document.body

                    // minimize btn
                    let minimizeBtnInterval = setInterval(() => {
                        if (childs && childs.childNodes[0]) {
                            clearInterval(minimizeBtnInterval)
                            childs.childNodes[0].click()
                        }
                    }, 100)
                }
            }, 100)
            let openChatBtn = window.document.getElementById('zsiq_float')
            let hideChatInterval = setInterval(() => {
                if (openChatBtn) {
                    clearInterval(hideChatInterval)
                    // openChatBtn.style.setProperty('visibility', 'hidden', 'important')
                    openChatBtn.style.setProperty('display', 'none', 'important')
                }
            }, 100)
        },
        openPablot() {
            let openBtn = window.document.getElementsByClassName('gccc5627964a9')
            let openInterval = setInterval(() => {
                if (openBtn && openBtn[0] && openBtn[0].childNodes[0] && openBtn[0].childNodes[0].childNodes[0]) {
                    clearInterval(openInterval)
                    openBtn[0].childNodes[0].childNodes[0].click()
                    let minimizeBtnHide = window.document.getElementsByClassName('gccc5627964a9')[0]
                    let hideInterval = setInterval(() => {
                        if (minimizeBtnHide) {
                            clearInterval(hideInterval)
                            minimizeBtnHide.style.setProperty('display', 'none', 'important')

                            let chatBox = window.document.getElementsByClassName('gccc41f11c2af')[0]
                            let chatBoxInterval = setInterval(() => {
                                if (chatBox) {
                                    clearInterval(chatBoxInterval)
                                    chatBox.style.setProperty('width', '90%', 'important')
                                    chatBox.style.setProperty('height', '80%', 'important')
                                    chatBox.style.setProperty('min-width', '330px', 'important')
                                    chatBox.style.setProperty('max-width', '700px', 'important')
                                    chatBox.style.setProperty('position', 'absolute', 'important')
                                    chatBox.style.setProperty('top', '80px', 'important')
                                    chatBox.style.setProperty('left', '0px', 'important')
                                    chatBox.style.setProperty('right', '0px', 'important')
                                    chatBox.style.setProperty('margin-left', 'auto', 'important')
                                    chatBox.style.setProperty('margin-right', 'auto', 'important')
                                }
                            }, 100)
                        }
                    }, 100)
                }
            }, 100)
        },
        closePablot() {
            let openBtn = window.document.getElementsByClassName('gccc5627964a9')
            let openInterval = setInterval(() => {
                if (openBtn && openBtn[0] && openBtn[0].childNodes[0] && openBtn[0].childNodes[0].childNodes[0]) {
                    clearInterval(openInterval)
                    openBtn[0].childNodes[0].childNodes[0].click()
                    openBtn[0].style.setProperty('display', 'none', 'important')
                    // openBtn[0].style.setProperty('opacity', '0', 'important')
                }
            }, 100)
        },
        openChat() {
            let openChatBtn = window.document.getElementById('zsiq_float')
            if (openChatBtn) {
                openChatBtn.click()
            }

            let interval = setInterval(() => {
                let container = window.document.getElementsByClassName('zls-sptwndw  siqembed siqtrans zsiq-mobhgt zsiq-newtheme siq_rht zsiq_size3 siqanim')[0]
                if (container) {
                    clearInterval(interval)
                    container.style.setProperty('width', '90%', 'important')
                    container.style.setProperty('height', '80%', 'important')
                    container.style.setProperty('overflow', 'hidden', 'important')
                    container.style.setProperty('min-width', '330px', 'important')
                    container.style.setProperty('max-width', '700px', 'important')
                    container.style.setProperty('position', 'absolute', 'important')
                    container.style.setProperty('top', '80px', 'important')
                    container.style.setProperty('left', '0px', 'important')
                    container.style.setProperty('right', '0px', 'important')
                    container.style.setProperty('margin-left', 'auto', 'important')
                    container.style.setProperty('margin-right', 'auto', 'important')

                    let deleteOpenChatBtn = window.document.getElementsByClassName('zsiq_floatmain zsiq_theme1 siq_bR siq_noanim zsiqfanim siq_noanim')[0]
                    if (deleteOpenChatBtn) {
                        deleteOpenChatBtn.style.setProperty('display', 'none', 'important')
                    }
                    let iframe = window.document.getElementById('siqiframe')
                    // get all childs of iframe
                    let childs = iframe.contentWindow.document.body.childNodes

                    // minimize btn
                    let minimizeBtnInterval = setInterval(() => {
                        if (childs[0]) {
                            clearInterval(minimizeBtnInterval)
                            childs[0].style.setProperty('display', 'none', 'important')
                            // childs[0].style.setProperty('height', '0', 'important')
                        }
                    }, 100)

                    let header = iframe.contentWindow.document.body.childNodes[1]
                    let headerInterval = setInterval(() => {
                        if (header && header.childNodes[0]) {
                            clearInterval(headerInterval)
                            header.childNodes[0].style.setProperty('display', 'none', 'important')
                        }
                    }, 200)
                    // overlay for header
                }
            }, 100)
        },
        loadScript() {
            let self = this
            console.log('load fuera if')
            if (this.chatActive && !this.chatLoaded) {
                console.log('load dentro if')
                let Script = document.createElement('script')
                Script.setAttribute('src', (this.$store.getters.getIsApp ? '' : window.location.hash == '' ? '/chat/' : window.location.pathname) + 'js/chat.js?' + process.env.VUE_APP_VERSION)
                document.head.appendChild(Script)

                Script.onload = () => {
                    console.log('onload')
                    self.chatLoading = false
                    console.log('onload')
                    let openChatInterval = setInterval(() => {
                        if (window.document.getElementById('zsiq_float')) {
                            clearInterval(openChatInterval)
                            if (self.chatSelected) {
                                self.openChat()
                                self.closePablot()
                            } else {
                                self.closeChat()
                                self.hideChat()
                            }
                        }
                    }, 100)
                }
            } else if (this.chatActive) {
                this.chatLoading = false
            }
        }
    },
    mounted() {
        let self = this
        if (this.$route.query.bot == 1) {
            this.chatSelected = false
            this.loadScript()
            this.openPablot()
        } else {
            this.loadScript()
            this.closePablot()
        }
        document.querySelectorAll('*').forEach(elem => {
            if (elem.offsetWidth > document.documentElement.offsetWidth) {
                console.log('Problem child: ', el)
            }
        })
    },
    beforeCreate() {
        // zld720342000000002056dragpos
        let name = 'zld720342000000002056dragpos'
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
}
</script>

<style lang="scss">
#chat.chat-container {
    background-color: #fff;
    height: 60px;
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .box-selector {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        @include border-radius(4px);
        width: 100%;
        max-width: 500px;
        height: 40px;
        padding: 3px;
        background-color: #fff;
        margin: 0 auto;
        margin-bottom: 24px;
        .chat,
        .auto-help {
            @include display-flex();
            @include justify-content(center);
            @include align-items(center);
            @include border-radius(4px);
            @include font-size(sm);
            font-family: $text;
            color: $neutro-s50;
            width: 50%;
            text-align: center;
            height: 34px;
            cursor: pointer;

            &.selected {
                font-family: $text-sbold;
                background-color: $main-t80;
                color: $main-dark;
                cursor: default;
            }
            &:hover:not(.selected) {
                @include bgHover(#fff);
            }
        }
    }
}
</style>
