<template>
    <div id="content">
        <router-view />
    </div>
</template>

<script>
import { Api } from '@/core/api.js'
const api = new Api()

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {}
}
</script>

<style lang="scss" scoped>
#content {
    height: calc(100%);
}
</style>
