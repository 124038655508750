import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from '@/i18n'

import Empty from '@/views/Empty.vue'
import RequestDemo from '@/views/RequestDemo'
import Calculator from '@/views/Calculator'
import CostCalculator from '@/views/CostCalculator'
import Signup from '@/views/Signup'
import Chat from '@/views/Chat'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        // en el .env poner la view a la que se quiere poner como principal
        component: function() {
            return import('./views/' + process.env.VUE_APP_MODE + '.vue')
        },
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/request-demo',
        name: 'RequestDemo',
        component: RequestDemo,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/calculate',
        name: 'Calculator',
        component: Calculator,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/cost',
        name: 'CostCalculator',
        component: CostCalculator,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/404',
        name: '404',
        component: Empty,
        meta: {
            layout: 'empty'
        }
    },
    {
        path: '/:lang',
        name: 'RequestDemo',
        component: RequestDemo,
        meta: {
            layout: 'empty'
        },
        beforeEnter: checkLanguage
    },
    {
        path: '/*',
        name: 'Empty',
        redirect: { name: '404' }
    }
]

const router = new VueRouter({
    mode: process.env.VUE_APP_URL_TYPE == 'HASH' ? 'hash' : 'history',
    routes
})

export default router

/**
 * Load language for new password and reset password
 */
function checkLanguage(to, from, next) {
    let lang = to.query.lang ? to.query.lang : to.params.lang
    let languages = ['es', 'en', 'pt', 'de']

    if (!languages.includes(lang)) {
        lang = 'es'
    }

    // get language
    i18n.loadLanguageAsync(lang).then(function(response) {
        next()
    })
}
