<template>
    <div class="outro-container">
        <div class="outro-content">
            <div class="image-outro">
                <img src="~@/../public/img/andy_pasos1.svg" alt="" />
            </div>
            <div class="description-outro">
                <div class="description" v-html="$t('public.signup.outro.description')"></div>
                <div class="confirm-text">
                    {{ $t('public.signup.outro.confirm') }}
                </div>
                <div class="legal-text" v-if="legalConditions()">
                    {{ $t('public.signup.outro.legal') }}
                </div>
                <div class="element terms_and_conditions" v-if="legalConditions()">
                    <div class="terms">
                        <div class="checkbox">
                            <input type="checkbox" v-model="terms_and_conditions" />
                        </div>
                        <div class="description-terms">
                            <div class="part1" :class="{ error: error.terms_and_conditions }" @click="terms_and_conditions = !terms_and_conditions">{{ $t('signup.form.terms_and_conditions') }}</div>
                        </div>
                    </div>
                    <div class="errorLabel" v-if="error.terms_and_conditions">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field_check') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="element send">
                <div class="return" @click="returnStep()">
                    {{ $t('signup.form.return') }}
                </div>
                <div class="next" @click="nextStep()">
                    {{ $t('public.signup.confirm') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Outro',
    data() {
        return {
            terms_and_conditions: false,
            error: {
                terms_and_conditions: false
            }
        }
    },
    methods: {
        nextStep() {
            var self = this
            var user = JSON.parse(localStorage.getItem('user'))
            var lead = JSON.parse(localStorage.getItem('lead'))
            var params = {
                lead_id: lead.id,
                employee_name: user.name,
                employee_surname: user.surname,
                employee_email: user.email,
                employee_password: sha256(user.password)
            }

            if (this.legalConditions() && this.terms_and_conditions) {
                this.$overlay.loading()
                this.$store.dispatch('lead/saveLead', params).then(function(response) {
                    self.$gtag.event('complete_signup', {
                        event_category: 'signup'
                    })
                    // console.log(response)
                    localStorage.removeItem('user')
                    localStorage.removeItem('lead')
                    self.$overlay.hide()
                    window.location.href = response.data
                })
            } else if (!this.legalConditions()) {
                this.$overlay.loading()
                this.$store.dispatch('lead/saveLead', params).then(function(response) {
                    self.$gtag.event('complete_signup', {
                        event_category: 'signup'
                    })
                    localStorage.removeItem('user')
                    localStorage.removeItem('lead')
                    self.$overlay.hide()
                    window.location.href = response.data
                })
            } else {
                this.error.terms_and_conditions = true
            }
            this.$emit('nextStep')
        },
        returnStep() {
            this.$emit('previousStep')
        },
        legalConditions() {
            if (this.$t('public.signup.outro.legal') == '') {
                return false
            }
            return true
        }
    }
}
</script>

<style lang="scss" scoped>
.outro-container {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .outro-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .title-outro {
            @include font-size(xl);
            text-align: center;
            font-family: $text-bold;
            color: $second;
        }

        .description-outro {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(space-between);
            width: 100%;
            height: calc(100% - 110px);

            .description {
                @include font-size(xl);
                text-align: center;
                font-family: $text-bold;
                color: $second;
                padding-top: 20px;
            }
            .confirm-text {
                color: #666666;
                font-size: 16px;
                font-family: $text-light;
            }
            .terms_and_conditions {
                width: 100%;
                height: 30px;
                .terms {
                    width: 100%;
                    display: inline-flex;
                }

                .checkbox {
                    width: auto;
                    margin-right: 6px;
                    @include display-flex();
                    @include align-items(center);

                    input {
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }
                }

                .description-terms {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                }
                .value {
                    width: 100%;

                    .bold {
                        @include font-size(xs);
                        display: inline-block;
                        color: $error-s40;
                        font-family: $text-bold;
                        margin-right: 2px;
                    }

                    .normal {
                        @include font-size(xs);
                        display: inline-block;
                        color: $error-s40;
                        font-family: $text;
                    }
                }
            }
        }
        .image-outro {
            @include display-flex();
            @include justify-content(center);
            width: 100%;

            img {
                width: 100%;
                max-width: 300px;
            }
        }
        .send {
            @include display-flex();
            @include justify-content(space-between);
            @include align-items(flex-end);
            width: 100%;
            height: 110px;

            .return {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include border-radius(3px);
                @include font-size(ml);
                width: 49%;
                height: 50px;
                background-color: $second-t90;
                color: $second-dark;
                font-family: $text-bold;
                cursor: pointer;

                &:hover {
                    background-color: $second-t80;
                }
            }
            .next {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include border-radius(3px);
                @include font-size(ml);
                width: 49%;
                height: 50px;
                background-color: $second;
                color: #ffffff;
                font-family: $text-bold;
                cursor: pointer;

                &:hover {
                    background-color: $second-s20;
                }
            }
        }
    }
}
</style>
