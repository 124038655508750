<template>
    <div class="intro-container">
        <div class="intro-content">
            <div class="title-intro">
                {{ $t('public.signup.intro.title') }}
            </div>
            <div class="description-intro">
                {{ $t('public.signup.intro.description') }}
            </div>
            <div class="image-intro">
                <img src="~@/../public/img/andy_kitchen.png" alt="" />
            </div>
            <div class="element send">
                <div class="next" @click="$emit('nextStep')">
                    {{ $t('public.signup.next') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style lang="scss" scoped>
.intro-container {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .intro-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .title-intro {
            @include font-size(xl);
            text-align: center;
            font-family: $text-bold;
            color: $second;
        }

        .description-intro {
            @include font-size(xl);
            text-align: center;
            font-family: $text-bold;
            color: $second;
        }
        .image-intro {
            @include display-flex();
            @include justify-content(center);
            width: 100%;

            img {
                width: 100%;
                max-width: 300px;
            }
        }
        .send {
            @include display-flex();
            @include justify-content(flex-end);
            @include align-items();
            width: 100%;

            .next {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include border-radius(3px);
                @include font-size(ml);
                width: 49%;
                height: 50px;
                background-color: $second;
                color: #ffffff;
                font-family: $text-bold;
                cursor: pointer;

                &:hover {
                    background-color: $second-s20;
                }
            }
        }
    }
}
</style>
