<template>
    <div class="singup">
        <!-- <MenuTop></MenuTop> -->
        <div class="logo" @click="returnHomePage()"></div>

        <div class="content">
            <div class="part left">
                <div class="header">En papel</div>
                <div class="question">
                    <div class="name">Coste papel</div>
                    <div class="value">
                        <input class="input" min="0" type="number" v-model="cost_paper" />
                        <span class="units">€</span>
                    </div>
                </div>

                <div class="question">
                    <div class="name">Coste impresión</div>
                    <div class="value">
                        <input class="input" min="0" type="number" v-model="cost_print" />
                        <span class="units">€</span>
                    </div>
                </div>

                <div class="question">
                    <div class="name">Coste archivadores y bolígrafos</div>
                    <div class="value">
                        <input class="input" min="0" type="number" v-model="cost_material" />
                        <span class="units">€</span>
                    </div>
                </div>

                <div class="question subtotal">
                    <div class="name"></div>
                    <div class="value">
                        <span class="subtotal">{{ formatNumber(cost_subtotal, 0) }} €</span>
                    </div>
                </div>

                <div class="separator" style="height: 64px;"></div>

                <div class="question head">
                    <div class="col col1"></div>
                    <div class="col col2">€/hora</div>
                    <div class="col col3">T.invertido h/sem</div>
                    <div class="col col4">T.invertido h/año</div>
                    <div class="col col5"></div>
                </div>
                <div class="question">
                    <div class="col col1">Tiempo de personal</div>
                    <div class="col col2">
                        <input class="input" min="0" type="number" v-model="cost_personal" />
                        <span class="units">€</span>
                    </div>
                    <div class="col col3">
                        <input class="input" min="0" type="number" v-model="time_personal" />
                        <span class="units">h</span>
                    </div>
                    <div class="col col4">{{ formatNumber(total_time_personal, 0) }} h</div>
                    <div class="col col5">{{ formatNumber(total_cost_personal, 0) }} €</div>
                </div>

                <div class="question">
                    <div class="col col1">Tiempo de gestión</div>
                    <div class="col col2">
                        <input class="input" min="0" type="number" v-model="cost_manage" />
                        <span class="units">€</span>
                    </div>
                    <div class="col col3">
                        <input class="input" min="0" type="number" v-model="time_manage" />
                        <span class="units">h</span>
                    </div>
                    <div class="col col4">{{ formatNumber(total_time_manage, 0) }} h</div>
                    <div class="col col5">{{ formatNumber(total_cost_manage, 0) }} €</div>
                </div>

                <div class="question subtotal">
                    <div class="name"></div>
                    <div class="value">
                        <span class="subtotal">{{ formatNumber(time_subtotal, 0) }} €</span>
                    </div>
                </div>

                <div class="question total">
                    <div class="name">TOTAL</div>
                    <div class="value">
                        <span class="total">{{ formatNumber(total, 0) }} €</span>
                    </div>
                </div>
            </div>
            <div class="part right">
                <div class="header">Con Andy</div>
                <div class="question">
                    <div class="name">Coste papel</div>
                    <div class="value">
                        <span class="subtotal item">{{ formatNumber(0, 0) }} €</span>
                    </div>
                </div>

                <div class="question">
                    <div class="name">Coste impresión</div>
                    <div class="value">
                        <span class="subtotal item">{{ formatNumber(0, 0) }} €</span>
                    </div>
                </div>

                <div class="question">
                    <div class="name">Coste archivadores y bolígrafos</div>
                    <div class="value">
                        <span class="subtotal item">{{ formatNumber(0, 0) }} €</span>
                    </div>
                </div>

                <div class="question subtotal">
                    <div class="name"></div>
                    <div class="value">
                        <span class="subtotal">{{ formatNumber(0, 0) }} €</span>
                    </div>
                </div>

                <div class="question" style="margin-bottom: 30px; margin-top: 0px;">
                    <div class="col col1">Licencia Andy</div>
                    <div class="col col2"></div>
                    <div class="col col3">
                        <input class="input" min="0" type="number" v-model="andy_license" />
                        <span class="units">€</span>
                    </div>
                    <div class="col col5" style="font-weight: bold;">{{ formatNumber(andy_license_year, 0) }} €</div>
                </div>

                <div class="question head">
                    <div class="col col1"></div>
                    <div class="col col2">€/hora</div>
                    <div class="col col3">T.invertido h/sem</div>
                    <div class="col col4">T.invertido h/año</div>
                    <div class="col col5"></div>
                </div>
                <div class="question">
                    <div class="col col1">Tiempo de personal</div>
                    <div class="col col2 center">{{ cost_personal }} €</div>
                    <div class="col col3 center">{{ formatNumber(andy_time_personal, 1) }} h</div>
                    <div class="col col4">{{ formatNumber(andy_total_time_personal, 0) }} h</div>
                    <div class="col col5">{{ formatNumber(andy_total_cost_personal, 0) }} €</div>
                </div>

                <div class="question">
                    <div class="col col1">Tiempo de gestión</div>
                    <div class="col col2 center">{{ cost_manage }} €</div>
                    <div class="col col3 center">{{ formatNumber(andy_time_manage, 1) }} h</div>
                    <div class="col col4">{{ formatNumber(andy_total_time_manage, 0) }} h</div>
                    <div class="col col5">{{ formatNumber(andy_total_cost_manage, 0) }} €</div>
                </div>

                <div class="question subtotal">
                    <div class="name"></div>
                    <div class="value">
                        <span class="subtotal">{{ formatNumber(andy_time_subtotal, 0) }} €</span>
                    </div>
                </div>

                <div class="question total">
                    <div class="name">TOTAL</div>
                    <div class="value">
                        <span class="total">{{ formatNumber(andy_total, 0) }} €</span>
                    </div>
                </div>
            </div>

            <div class="part full">
                <div class="half">
                    <div class="question">
                        <div class="name">Diferencia por local en 1 año</div>
                        <div class="value">
                            <span class="total">{{ formatNumber(diff_local, 0) }} €</span>
                        </div>
                    </div>
                    <div class="question">
                        <div class="name">Número de locales</div>
                        <div class="value">
                            <input class="input" min="0" type="number" v-model="sites" style="width: 100%;" />
                            <span class="units"></span>
                        </div>
                    </div>
                </div>
                <div class="half">
                    <div class="question final">
                        <div class="name">AHORRO TOTAL</div>
                        <div class="value">
                            <span class="total">{{ formatNumber(diff_total, 0) }} €</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuTop from '@/components/layout/MenuTop'

export default {
    components: { MenuTop },
    name: 'CostCalculator',
    data() {
        return {
            // CALCULATOR
            locals: 0,
            mode: false,
            logs: false,
            time: 0,
            cost_paper: 45,
            cost_print: 240,
            cost_material: 100,
            cost_personal: 12.0,
            time_personal: 7,
            cost_manage: 15.0,
            time_manage: 1.5,
            andy_license: 45,
            sites: 5
        }
    },
    computed: {
        logsItems() {
            return ['Registro de temperatura', 'Registro de limpieza', 'Registro de recepción producto']
        },

        canCalculate() {
            return this.locals && this.mode && this.logs && this.logs.length > 0 && this.time
        },
        result() {
            return parseInt(this.cost_paper) + parseInt(this.cost_print) + parseInt(this.cost_material)
        },
        cost_subtotal() {
            return parseInt(this.cost_paper) + parseInt(this.cost_print) + parseInt(this.cost_material)
        },
        total_time_personal() {
            return parseFloat(this.time_personal) * 52
        },
        total_cost_personal() {
            return this.total_time_personal * parseFloat(this.cost_personal)
        },
        total_time_manage() {
            return parseFloat(this.time_manage) * 52
        },
        total_cost_manage() {
            return parseFloat(this.total_time_manage) * parseFloat(this.cost_manage)
        },
        time_subtotal() {
            return this.total_cost_manage + this.total_cost_personal
        },
        total() {
            return this.time_subtotal + this.cost_subtotal
        },
        andy_time_personal() {
            return Math.round(this.time_personal * 0.6 * 10) / 10
        },
        andy_time_manage() {
            return Math.round(this.time_manage * 0.45 * 10) / 10
        },
        andy_total_time_personal() {
            return parseFloat(this.andy_time_personal) * 52
        },
        andy_total_cost_personal() {
            return parseFloat(this.andy_total_time_personal) * parseFloat(this.cost_personal)
        },
        andy_total_time_manage() {
            return parseFloat(this.andy_time_manage) * 52
        },
        andy_total_cost_manage() {
            return parseFloat(this.andy_total_time_manage) * parseFloat(this.cost_manage)
        },
        andy_time_subtotal() {
            return this.andy_total_cost_manage + this.andy_total_cost_personal
        },
        andy_total() {
            return this.andy_time_subtotal + this.andy_license_year
        },
        andy_license_year() {
            return parseFloat(this.andy_license) * 12
        },
        diff_local() {
            return this.total - this.andy_total
        },
        diff_total() {
            return this.diff_local * parseInt(this.sites)
        }
    },
    methods: {
        formatNumber(value, decimals = 2) {
            return parseFloat(value)
                .toFixed(decimals)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        },
        changeMode(mode) {
            this.mode = mode
        },

        calculate() {
            this.result = {}

            // SCROLL TO RESULT
            var element = document.getElementById('result')
            var top = element.offsetTop
            window.scrollTo({ top: document.body.clientWidth > 800 ? top - 60 : top - 40, behavior: 'smooth' })
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.singup {
    width: 100%;
    min-height: 100%;
    position: relative;
    background-color: #f3fafa;
    @include background($image: img('background.svg'), $position: center, $size: 1100px);
    top: 0;
    position: absolute;

    .logo {
        @include background($image: img('logo_andy.png'), $size: contain, $position: center center);
        width: 140px;
        height: 50px;
        cursor: pointer;
        -webkit-backface-visibility: hidden;
        margin: 25px auto;
        margin-top: 35px;
    }

    .content {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: calc(100%);
        width: 100%;
        padding-top: 25px;
        @include background($image: img('ellipse_second_t10.svg'), $position: bottom -300px right -150px, $size: clamp(unset, 50%, 1000px));
        // background: linear-gradient(284.46deg, #8dbacc 0%, #dcf4ed 100%);
        overflow-y: hidden;
        overflow: hidden;

        padding: 100px 50px 80px;
        padding: 25px 50px 80px;

        .part {
            padding: 60px 40px;
            z-index: 50;
            position: relative;

            .header {
                padding: 15px;
                background: #1a9e9a;
                font-family: $text-bold;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }

            .input {
                border: 2px solid #1a9e9a;
                line-height: 25px;
                background-color: #e1fffe !important;
            }

            .label {
                @include font-size(30px);
                color: $neutro-s70;
                font-family: $text-bold;
            }

            .question {
                @include display-inline-flex();
                @include flex-wrap(wrap);
                margin-top: 5px;
                width: 100%;
                color: $neutro-s40;

                &.final {
                    text-align: center;
                    .name {
                        text-align: center;
                        font-size: 18px;
                        margin-top: 5px;
                        width: 100%;
                        align-items: center;
                        align-content: center;
                        @include justify-content(center);
                    }

                    .value {
                        width: 100%;
                        .total {
                            text-align: center;
                            font-size: 50px;
                            line-height: 40px;
                        }
                    }
                }

                &.total {
                    border-top: 0px solid #1a9e9a;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background: #bae9e7;
                    padding: 15px 40px;

                    .name {
                        font-size: 18px;
                        margin-top: 5px;
                        width: calc(100% - 200px);
                    }
                    .value {
                        width: 200px;
                    }
                }

                &.subtotal {
                    border-top: 2px solid #ddd;
                    margin-top: 10px;
                    margin-bottom: 30px;
                }

                &.head {
                    .col {
                        font-size: 12px !important;
                        text-align: center;
                        color: #999;
                    }
                }

                .col {
                    &.col1 {
                        font-size: 14px;
                        line-height: 30px;
                        flex: 1;
                        color: #333;
                        font-size: 14px;
                    }
                    &.col2 {
                        flex: 0 0 100px;
                        padding: 0 5px;
                    }
                    &.col3 {
                        flex: 0 0 100px;
                        padding: 0 5px;
                    }
                    &.col4 {
                        flex: 0 0 70px;
                        text-align: center;
                        font-family: $text;
                    }
                    &.col5 {
                        flex: 0 0 70px;
                        text-align: right;
                        font-family: $text;
                    }

                    &.center {
                        text-align: center;
                    }

                    .input {
                        width: calc(100% - 30px);
                        color: $neutro-s70;
                        font-family: $text-bold;
                    }
                    .units {
                        width: 30px;
                        font-family: $text;
                        line-height: 30px;
                        padding-left: 5px;
                        text-align: right;
                        color: #999;
                    }
                }

                .subtotal,
                .total {
                    width: 100%;
                    font-family: $text-bold;
                    font-size: 18px;
                    padding: 5px 0px;
                    margin-top: 0px;
                    text-align: right;
                    &.item {
                        font-family: $text;
                    }
                }
                .total {
                    font-size: 24px;
                    border-top: 0;
                    margin-top: 0;
                }

                &.filled {
                    color: #1a9e9a;

                    .box-attached {
                        color: #1a9e9a;
                    }
                }

                .name {
                    @include font-size(m);
                    font-family: $text;
                    width: calc(100% - 100px);
                    color: inherit;
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    line-height: 25px;
                    color: #333;
                    font-size: 14px;
                }

                .value {
                    @include display-inline-flex();
                    @include flex-wrap(wrap);
                    width: 100px;
                    .input {
                        width: calc(100% - 20px);
                        @include display-inline-flex();
                        @include flex-wrap(wrap);
                        font-family: $text-bold;
                        font-size: 18px;
                    }
                    .units {
                        width: 20px;
                        font-family: $text;
                        line-height: 30px;
                        padding-left: 10px;
                        text-align: right;
                        color: #999;
                    }
                }

                .description {
                    color: inherit;
                    font-family: $text-light;
                    width: calc(100% - 80px);
                }

                .box {
                    @include display-inline-flex();
                    @include justify-content(space-between);
                    @include align-items();
                    width: calc(100% - 90px);
                    margin: 10px 0 0;
                    height: fit-content;

                    .slider {
                        width: 100%;
                        height: fit-content;

                        .v-input {
                            margin: 0px !important;
                        }
                    }

                    .option {
                        @include display-inline-flex();
                        @include justify-content();
                        @include font-size(sm);
                        @include border-radius(20px);
                        width: 30%;
                        padding: 20px 20px;
                        color: #fff;
                        cursor: pointer;
                        opacity: 1 !important;

                        &.labeling,
                        &.appcc,
                        &.all {
                            background-color: $neutro-s40;

                            &.selected {
                                opacity: 1;
                                filter: grayscale(0%);
                            }
                        }

                        &.labeling.selected {
                            background-color: #1a9e9a;
                        }
                        &.appcc.selected {
                            background-color: #758c3e;
                        }
                        &.all.selected {
                            background-color: #475b96;
                        }
                    }

                    .v-select {
                        border: none !important;
                        color: #fff !important;
                    }
                }

                .box-attached {
                    @include display-inline-flex();
                    @include justify-content(center);
                    @include align-items(baseline);
                    width: 80px;
                    height: fit-content;
                    color: $neutro-s40;

                    .number,
                    .units {
                        @include display-inline-flex();
                        @include justify-content(bottom);
                        @include align-items(bottom);
                        font-family: $text-bold;
                        color: inherit;
                    }

                    .number {
                        width: fit-content;
                        @include font-size(xl);
                    }

                    .units {
                        width: fit-content;

                        @include font-size(s);
                    }
                }
            }

            .submit {
                @include font-size(l);
                @include border-radius(40px);
                font-family: $text-bold;
                background-color: $neutro-s40;
                color: #fff;
                margin: 0 auto;
                width: fit-content;
                margin-top: 30px;
                padding: 5px 30px;

                &.available {
                    background-color: #692c92;
                    cursor: pointer;
                }
            }
            &.left {
                @include box-shadow($h: 0px, $v: 4px, $b: 10px, $s: 0px, $c: rgba(0, 0, 0, 0.15));
                @include border-radius(10px);
                background-color: #fff;
                height: auto;
                float: left !important;
                width: calc(50% - 20px);
                max-width: 600px;
                overflow: hidden;
                margin-right: 20px;

                .header {
                    background: #e91e63;
                }

                .question {
                    &.total {
                        background: #e9aec2;
                    }
                }
            }
            &.right {
                @include box-shadow($h: 0px, $v: 4px, $b: 10px, $s: 0px, $c: rgba(0, 0, 0, 0.15));
                @include border-radius(10px);
                background-color: #fff;
                height: auto;
                float: left !important;
                width: calc(50% - 20px);
                max-width: 600px;
                overflow: hidden;
                margin-left: 20px;

                .try {
                    @include font-size(xl);
                    @include border-radius(40px);
                    font-family: $text-bold;
                    background-color: #ffc700;
                    color: #000;
                    margin: 0 auto;
                    width: fit-content;
                    padding: 7px 50px;
                    cursor: pointer;
                }
            }
            &.full {
                @include box-shadow($h: 0px, $v: 4px, $b: 10px, $s: 0px, $c: rgba(0, 0, 0, 0.15));
                @include border-radius(10px);
                background-color: #fff;
                height: auto;
                float: left !important;
                width: 100%;
                max-width: 1240px;
                overflow: hidden;
                margin-top: 40px;
                padding: 20px 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .half {
                    width: 50%;
                    padding: 0 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .singup {
        @include background($image: img('background.svg'), $position: left top, $size: 150vh);

        .content {
            padding: 50px 0 0 0;
            @include background($image: img('ellipse_second_t10.svg'), $position: bottom -150px right -50px, $size: 350px);

            .part {
                // padding: 40px 20px;
                &.left,
                &.right,
                &.full {
                    width: calc(100% - 40px) !important;
                    max-width: unset;
                    margin: 20px auto;
                    padding: 70px 20px;
                }
                &.full {
                    padding: 20px 10px;
                }

                &.left {
                    .question {
                        .name {
                            // width: calc(100%);
                        }

                        .description {
                            width: 100%;
                        }

                        .box {
                            width: 100%;

                            .option {
                                @include display-inline-flex();
                                @include justify-content();
                                @include align-items();
                                @include font-size(sm);
                                @include border-radius(20px);
                                width: 31%;
                                padding: 20px 10px;
                                height: 60px;
                                color: #fff;
                                cursor: pointer;
                            }
                        }

                        .box-attached {
                            margin-top: 5px;
                            margin-left: auto;
                        }
                    }
                }

                &.right {
                    .try {
                        @include justify-content();
                        @include align-items();
                        padding: 7px 0px;
                        width: 90%;
                        margin-bottom: 50px;
                    }
                }

                .question.final .value .total {
                    font-size: 40px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
}
</style>

// CUSTOM VUETIFY STYLES
<style lang="scss">
.question {
    &.filled {
        .v-slider__tick {
            background-color: #1a9e9a;
        }
    }

    .select-white.v-select {
        border-radius: 12px !important;
        border: none !important;

        .v-select__selection {
            color: white !important;
            width: 100%;
            margin: 6px 0 !important;
        }

        .v-input__append-inner {
            height: 100%;
            margin: 0 !important;

            div {
                height: 100%;
            }

            i {
                content: '';
                color: transparent;
                @include background($image: img('down_arrow_ffffff.svg'), $position: center, $size: 80%);
                margin-top: 0 !important;
                height: 100%;
            }
        }

        .v-input__slot {
            padding: 5px 12px !important;
        }
    }

    .v-input__slider .v-slider .v-slider__thumb {
        display: inherit;
        cursor: pointer;
        width: 24px;
        height: 24px;
        left: -12px;
    }

    .v-slider__tick {
        height: 12px !important;
        top: calc(50% - 6px) !important;
        background-color: $neutro-s30;
        // cursor: pointer;
        width: 3px !important;
    }
}
</style>
