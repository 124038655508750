<template>
    <div class="signup-form">
        <div class="signup-content">
            <div class="form-title">
                <div class="element">
                    <div class="title-form">
                        {{ $t('public.signup.user.title') }}
                    </div>
                </div>
                <div class="element">
                    <div class="description" v-html="$t('public.signup.user.description')"></div>
                </div>
            </div>
            <!-- Name -->
            <div class="form-content">
                <div class="element">
                    <div class="label">
                        <div class="value" v-if="user.name.length > 0">{{ $t('signup.form.name_panel') }}</div>
                    </div>
                    <div class="input">
                        <input type="text" data-hj-allow v-model="user.name" class="name" :class="{ filled: user.name.length > 0, error: error.name }" :placeholder="$t('signup.form.name_panel')" />
                    </div>
                    <div class="errorLabel" v-if="error.name">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                        </div>
                    </div>
                </div>

                <!-- Identification -->
                <div class="element">
                    <div class="label">
                        <div class="value" v-if="user.surname.length > 0">{{ $t('signup.form.surnames') }}</div>
                    </div>
                    <div class="input">
                        <input type="text" data-hj-allow v-model="user.surname" class="name" :class="{ filled: user.surname.length > 0, error: error.surname }" :placeholder="$t('signup.form.surnames')" />
                    </div>
                    <div class="errorLabel" v-if="error.surname">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                        </div>
                    </div>
                </div>

                <!-- Email -->
                <div class="element">
                    <div class="label">
                        <div class="value" v-if="user.email.length > 0">{{ $t('public.signup.user_email') }}</div>
                    </div>
                    <div class="input">
                        <input type="email" data-hj-allow v-model="user.email" class="email" :class="{ filled: user.email.length > 0, error: error.email || error.email_taken }" :placeholder="$t('public.signup.user_email')" />
                    </div>
                    <div class="errorLabel" v-if="error.email">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            <div class="normal">{{ $t('signup.form.error.email') }}</div>
                        </div>
                    </div>
                    <div class="errorLabel" v-if="error.email_taken">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                            <div class="normal">{{ $t('signup.form.error.email_taken') }}</div>
                        </div>
                    </div>
                </div>

                <!-- Password -->
                <div class="element">
                    <div class="label">
                        <div class="value" v-if="user.password.length > 0">{{ $t('public.signup.site.password') }}</div>
                    </div>
                    <div class="input" v-if="user">
                        <input type="password" v-model="user.password" class="padlock" :class="{ filled: user.password && user.password.length > 0, error: error.password }" :placeholder="$t('public.signup.site.password_employee')" />
                    </div>
                    <div class="errorLabel" v-if="error.password">
                        <div class="value">
                            <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-form">
                <!-- Footer Buttons -->
                <div class="element send">
                    <div class="return" @click="returnStep()">
                        {{ $t('signup.form.return') }}
                    </div>
                    <div class="next" @click="nextStep()">
                        {{ $t('public.signup.next') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'leadForm',
    data() {
        return {
            user: {
                name: '',
                surname: '',
                email: '',
                password: ''
            },
            error: {
                name: false,
                surname: false,
                email: false,
                password: false,
                company: false,
                email_taken: false
            },
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    computed: {},
    methods: {
        nextStep() {
            this.validateForm().then(response => {
                this.$overlay.hide()
                if (response) {
                    localStorage.setItem('user', JSON.stringify(this.user))
                    this.$emit('nextStep')
                }
            })
        },
        returnStep() {
            this.$emit('previousStep')
        },
        async validateForm(showErrors = true) {
            this.$overlay.loading()
            let validate = true

            // Name
            if (this.user.name == '') {
                this.error.name = true
                validate = false
            } else {
                this.error.name = false
            }

            // Surname
            if (this.user.surname == '') {
                this.error.surname = true
                validate = false
            } else {
                this.error.surname = false
            }

            // Email
            if (this.user.email == '') {
                this.error.email = true
                validate = false
            }

            if ((showErrors && !this.emailReg.test(this.user.email)) || this.user.email == '') {
                this.error.email = true
                validate = false
            } else {
                this.error.email = false
            }

            if (!this.error.email) {
                var resp = await this.$store.dispatch('lead/validateEmailUser', { email: this.user.email })
                if (!resp.status) {
                    this.error.email_taken = true
                    validate = false
                } else {
                    console.log(resp)
                    this.error.email_taken = false
                }
            }

            // password
            if (this.user.password == '' || this.user.password.length < 8) {
                this.error.password = true
                validate = false
            } else {
                this.error.password = false
            }

            return validate
        }
    },
    mounted() {
        let lead = localStorage.getItem('lead')
        if (lead) {
            lead = JSON.parse(lead)
            this.user.name = lead.name
            this.user.surname = lead.surname
            this.user.email = lead.email
            this.user.password = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.signup-form {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 12px;
    padding-top: 100px;

    .signup-content {
        width: 100%;
        height: 100%;
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        max-width: 400px;

        .form-content,
        .form-title,
        .footer-form {
            width: 100%;

            .element {
                height: auto;
                min-height: 50px;
                width: 100%;
                margin: 0 0 10px 0;
                padding: 0;

                .label {
                    @include display-flex();
                    @include justify-content(flex-start);
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    height: 20px;
                    width: 100%;
                    .value {
                        height: 100%;
                        width: 300px;
                        @include font-size(sm);
                        color: $inactive-s30;
                    }
                }

                .input {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include border-radius(3px);
                    height: 55px;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    input {
                        @include font-size(m);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: #000;
                        padding: 10px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.name {
                            @include background($image: img('profile_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('profile_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.email {
                            @include background($image: img('mail_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('mail_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.padlock {
                            @include background($image: img('padlock_neutro.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('padlock.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.company {
                            @include background($image: img('site_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('site_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        &::placeholder {
                            @include font-size(m);
                            font-family: $text;
                            color: $inactive-s30;
                        }
                    }
                }

                .errorLabel {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(flex-start);
                    @include flex-wrap(wrap);
                    width: 100%;

                    .value {
                        width: 100%;

                        .bold {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text-bold;
                            margin-right: 2px;
                        }

                        .normal {
                            @include font-size(xs);
                            display: inline-block;
                            color: $error-s40;
                            font-family: $text;
                        }
                    }
                }

                &.sites {
                    select {
                        @include font-size(m);
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        height: 100%;
                        width: 100%;
                        font-family: $text;
                        color: $inactive-s30;
                        padding: 9px 0 10px 40px;
                        border: 1px solid $neutro;

                        &.company {
                            @include background($image: img('location_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                            &.filled {
                                @include background($image: img('location_000000.svg'), $size: 20px, $position: left 10px center);
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }

                        &:focus {
                            border: 1px solid $second;
                        }

                        option[value=''][disabled] {
                            display: none;
                        }

                        &.filled {
                            color: #000;
                        }
                    }
                }

                .options {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);

                    .option {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include background($color: $neutro-t80);
                        @include border-radius(3px);
                        border: 1px solid $neutro;
                        height: 40px;
                        width: auto;
                        margin: 5px;
                        padding: 12px;
                        cursor: pointer;

                        &.selected {
                            @include background($color: $second);
                            color: #ffffff;

                            &.error {
                                border: 1px solid $neutro-t80;
                            }
                        }

                        &.error {
                            border: 1px solid $error;
                        }
                    }
                }

                // &.sites.desktop {
                //     height: auto;
                // }

                // &.sites.mobile {
                //     display: none;
                // }

                &.terms_and_conditions {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    margin: 0;

                    .checkbox {
                        width: auto;
                        margin-right: 6px;

                        input {
                            width: 15px;
                            height: 15px;
                            cursor: pointer;
                        }
                    }

                    .description {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);

                        .part1 {
                            cursor: pointer;

                            &.error {
                                color: $error;
                            }
                        }

                        .part2 {
                            margin-left: 5px;

                            a {
                                text-decoration: underline;
                                text-decoration-color: $second;
                            }

                            &.error {
                                color: $error;
                            }
                        }
                    }
                }

                &.send {
                    @include display-flex();
                    @include justify-content(space-between);
                    @include align-items();

                    .return {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second-t90;
                        color: $second-dark;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-t80;
                        }
                    }
                    .next {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include border-radius(3px);
                        @include font-size(ml);
                        width: 49%;
                        height: 50px;
                        background-color: $second;
                        color: #ffffff;
                        font-family: $text-bold;
                        cursor: pointer;

                        &:hover {
                            background-color: $second-s20;
                        }
                    }
                }
                .title-form {
                    @include font-size(l);
                    text-align: center;
                    font-family: $text-bold;
                    color: $second;
                }
                .description {
                    @include font-size(m);
                    text-align: center;
                    font-family: $text-light;
                    color: $neutro-s70;
                }
            }
        }
        .footer-form {
            @include display-flex();
            @include align-items(flex-end);
            height: 100%;

            .element {
                margin: 0px;
            }
        }

        @media screen and (min-height: 840px) {
            // background-color: red;
        }
    }
}
</style>
